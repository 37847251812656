import {
    Sales,
    Orderbook,
    Despatch,
    Login,
    Settings,
    History
} from "pages";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import MainLayout from "components/layout/main"
import useAuthStore from 'store/auth'
import { useQueryClient } from "react-query"
import { Products, Accounts, Wholesalers, Announcements } from 'components/Settings'
import { hasPermission } from "components/Permissions";
import { ROLES } from "util/constants/permissions";
import { isBefore } from "date-fns";


const RouteGuard = ({ children }) => {
    const location = useLocation()
    const queryClient = useQueryClient()
    const isLoggedIn = useAuthStore(state => state.isLoggedIn)
    const expiredDate = useAuthStore(state => state.expiryDate)
    const resetAuth = useAuthStore(state => state.resetAuth)

    const dateNow = new Date()

    if (!isLoggedIn || (expiredDate && isBefore(new Date(expiredDate), dateNow))) {
        queryClient.clear()
        resetAuth()
        return <Navigate to="/login" state={{from: location}} replace />
    }

    return children
}

const LoginGuard = ({ children }) => {
    const isLoggedIn = useAuthStore(state => state.isLoggedIn)

    if (isLoggedIn) {
        return <Navigate to="/" replace />
    }

    return children
}

// const DashboardGuard = ({ children }) => {
//     const userRole = useAuthStore(state => state.role)

//     if (userRole !== ROLES.factory && userRole !== ROLES.wholesaler) {
//         return children
//     }

//     return <Navigate to="/sales" replace/>
// }

const SettingsGuard = ({ children }) => {
    const userRole = useAuthStore(state => state.role)
    const roleScope = [ROLES.admin, ROLES.sales]

    if (hasPermission({ roleScope, role: userRole })) {
        return children
    }

    return <Navigate to="/sales" replace/>
}

const AppRoute = () => {
  
    return (
        <Router>
            <Routes>
                {/* / and /sales path just to ensure that default path is taken care of which is the default load */}
                <Route path="/login" element={
                    <LoginGuard>
                        <Login />
                    </LoginGuard>
                } />


                <Route path="/" element={
                    <RouteGuard>
                        <MainLayout/>
                    </RouteGuard>
                }>
                    <Route index element={<Sales />}/>
                    <Route path="sales" element={<Sales />} />
                    <Route path="orderbook" element={<Orderbook />} />
                    <Route path="despatch" element={<Despatch />} />
                    <Route path="history" element={<History />}/>
                    <Route path="settings" element={
                        <SettingsGuard>
                            <Settings />
                        </SettingsGuard>
                    }>
                        <Route index element={<Products />}/>
                        <Route path="products" element={<Products />}/>
                        <Route path="accounts" element={<Accounts />} />
                        <Route path="announcements" element={<Announcements/>} />
                        <Route path="wholesaler" element={<Wholesalers />}/>
                    </Route>
                </Route>
            </Routes>
        </Router>
    )
}

export default AppRoute