import OrderModal from './Orders';
import ProductModal from './Products';
import CustomerModal from './Customer';
import { PCEntry } from './PCEntry';
import SimpleModal from './Simple';
import EmployeeActionsModal from './EmployeeActions';
import ImageListModal from './ImageListModal';
import { PartsEntry } from './PartsEntry';
import OrderNoModal from './OrderNo';

export const ActionModal = {
    Order: OrderModal,
    Product: ProductModal,
    Customer: CustomerModal,
    PCForm: PCEntry,
    Simple: SimpleModal,
    Employee: EmployeeActionsModal,
    Parts: PartsEntry,
    ImageList: ImageListModal,
    AddOrderNo: OrderNoModal,
};
