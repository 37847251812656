import { TableCell, TableRow, IconButton, Collapse, Stack, Typography, Chip, Tooltip } from '@mui/material'
import { KeyboardArrowUp, KeyboardArrowDown, ErrorOutline, Check } from '@mui/icons-material';
import { Details } from 'components/Details'
import { CommentList } from 'components/CommentsList';
import { useMemo, useState, memo } from 'react';
import { getPrintedCount } from 'components/Details/details.data';
import { COLORS } from 'util/constants/commons';
import { format, parse } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import { PermissionBarrier, hasPermission } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import { LoadingButton } from '@mui/lab';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { useSnackbar } from 'notistack';
import useAuthStore from 'store/auth'
import { useQueryClient } from 'react-query';
import { usePermission } from 'hooks/permission.hooks';
import { ActionModal } from 'components/Modals';
import { stringRandomizer } from 'util/helpers/stringRandomizer';

const SalesRow = (props) => {
    const { row } = props
    const [open, setOpen] = useState(false)
    const roleScope = [ROLES.admin, ROLES.sales]
    const userRole = useAuthStore(state => state.role)

    const propData = [
        row.customer,
        row.reference,
        row.orderNo ? row.orderNo : hasPermission({roleScope, role: userRole}) ? <ActionModal.AddOrderNo rowData={row} /> : row.orderNo || ''
    ]

    return (
        <>
            <TableRow 
                selected={open} 
                key={stringRandomizer(row.id)}
                onClick={() => setOpen(!open)}>
                <TableCell sx={{borderBottom: 0}} id={row.id}>
                    <IconButton
                        align='center'
                         aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                {/* Regular Data Table */}
                {propData.map((curRow, index) => {
                    return <TableCell sx={{borderBottom: 0}} align='left' key={`regTable-${index}`}>{curRow}</TableCell>
                })}
            </TableRow>
            <TableRow selected={open}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack direction="row" alignContent={'center'} justifyContent={'center'}>
                            <Details.Order row={row} />

                            <Details.Umbrellas order={row} />
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default memo(SalesRow)