export const SETTINGS_MENU = [
    {
        key: 'products',
        displayName: 'Products',
        subHeader: 'Frames, Colours, Models creation and modification',
        icon: 'InventoryOutlined',
        permissions: '',
        children: [],
    },
    {
        key: 'accounts',
        displayName: 'Accounts',
        subHeader: 'Account creation and modification',
        icon: 'ManageAccounts',
        permissions: '',
        children: [],
    },

    {
        key: 'announcements',
        displayName: 'Announcements',
        subHeader: 'Create and Manage Announcements',
        icon: 'Announcement',
        permissions: '',
        children: [],
    },
    {
        key: 'wholesaler',
        displayName: 'Wholesalers',
        subHeader: 'Wholesaler settings modification',
        icon: 'AccountBox',
        permissions: '',
        children: [],
    },
];
