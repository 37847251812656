import { useCallback, useMemo, useState } from 'react'
import { 
    Typography, 
    Stack, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Box,
} from '@mui/material'

import { StyledPaper } from 'components/StyledPaper';
import { ExpandMore} from '@mui/icons-material';

import ProgressBar from 'components/Loaders/ProgressBar';
import { useColours, useFrames, useModelData } from 'hooks/lookups.hooks';
import { PRODUCT_FIELDS } from 'components/Modals/products.data';
import { stringRandomizer } from 'util/helpers/stringRandomizer';

const UmbrellaDetails = ({order, customAction, currPage = '', ...props}) => {
    const [selectedUmbrella, setSelectedUmbrella] = useState(-1)
    const { data: modelsDataLookup } = useModelData()    
    const { data: framesLookup } = useFrames()
    const { data: coloursLookup } = useColours()

    const handleExpand = (umbrellaIndex) => (event, isExpanded) => {
        setSelectedUmbrella(isExpanded ? umbrellaIndex : -1)
    }

    const _productDataList = useMemo(() => {
        return {
            models: modelsDataLookup.models,
            frames: framesLookup.frames,
            colours: coloursLookup.colours,
            bases: modelsDataLookup.models.filter((model) => model.displayName.toLowerCase().includes('base'))
        }
    }, [modelsDataLookup, framesLookup, coloursLookup])

    const _processedUmbrellas = useMemo(() => {
        const newUmbrellaList = order.umbrellas.map((umbrella) => {
            let builder = {}

            for (const dataField of Object.values(PRODUCT_FIELDS)) {

                if (umbrella[dataField.name]) {
                    builder[dataField.name] = _productDataList[dataField.options].find((targetData, index) => targetData.id === umbrella[dataField.name])
                }
            }

            return builder
        })

        return newUmbrellaList
    }, [order.umbrellas, _productDataList])
    
    const umbrellaDataBuilder = (sourceData) => {
        return Object.values(PRODUCT_FIELDS).map((data, index) => {
            return (
                <Stack key={stringRandomizer(data.name, index)} direction='row' spacing={1} sx={{pt: 1}} alignItems={'center'}>
                    <Typography sx={{fontWeight: 'bold'}} variant={'body1'}>
                        {data.label.replace('*', '').trim()}: &nbsp;
                    </Typography>
                
                    <Typography variant={'body1'} sx={{textAlign: 'left', maxWidth: 500 }}>
                        {sourceData[data.name] ? sourceData[data.name].displayName : 'N/A'}
                    </Typography>
                </Stack>
            )
        })
    }

    return (
        <StyledPaper elevation={6} sx={{flex: 1}}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" spacing={1}>
                <Typography variant={'h6'} sx={{fontWeight: 'bold'}} align="center">Umbrellas Information</Typography>

                {_processedUmbrellas.map((umbrellaData, index) => (
                    <Accordion 
                        key={`${index}-${stringRandomizer(umbrellaData.model.id, index)}`} 
                        expanded={selectedUmbrella === index} 
                        onChange={handleExpand(index)}
                    >
                        <AccordionSummary 
                            expandIcon={<ExpandMore />} 
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }
                            }}
                            aria-controls="product-panel-content" 
                        >
                            <Typography>
                                {`${umbrellaData.model.company} - ${umbrellaData.model.code}`}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            {umbrellaDataBuilder(umbrellaData)}
                        </AccordionDetails>
                    </Accordion>
                ))}
                
            </Stack>
        </StyledPaper>
    )
}

export default UmbrellaDetails
