import { useState, useRef, useMemo } from 'react'
import { 
    Typography, 
    Stack, 
    Divider, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    List, 
    ListItemButton, 
    ListItemText, 
    ListItem, 
    Chip, 
    ListItemIcon, 
    Modal,
    Container,
    Box,
    Button,
} from '@mui/material'
import ActionMenu from 'components/ActionMenu'
import { StyledPaper } from 'components/StyledPaper';
import { Check, ExpandMore, Error, ChevronRight } from '@mui/icons-material';
import ProductDetails from './ProductDetails'
import { ActionModal } from 'components/Modals';
import { PRODUCT_FIELDS, getProductData } from 'components/Modals/products.data';
import { getPrintedCount } from './details.data';
import { PermissionBarrier } from 'components/Permissions';
import { ROLES } from 'util/constants/permissions';
import { format, parse } from 'date-fns';
import { DISPLAY_FORMAT, SERVER_FORMAT } from 'util/constants/dates';
import SimpleModal from 'components/Modals/Simple';
import { API } from 'api';
import { API_ENDPOINTS } from 'util/constants/api';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { useColours, useFrames, useModelData } from 'hooks/lookups.hooks';

const OrderDetails = ({row, customAction, currPage = '', ...props}) => {
    // const { data: modelsDataLookup } = useModelData()    
    // const { data: framesLookup } = useFrames()
    // const { data: coloursLookup } = useColours()

    // const [isProductExpanded, setProductExpanded] = useState(false)
    // const [isModalToggled, setToggleModal] = useState(false) 
    // const [selectedProduct, setSelectedProduct] = useState(null)

    const queryClient = useQueryClient()
    // const backdropClick = useRef()

    // const { enqueueSnackbar } = useSnackbar()

    // const printCounters = useMemo(() => getPrintedCount(row.umbrellas), [row.umbrellas])

    // const _productDataList = useMemo(() => {
    //     return {
    //         models: modelsDataLookup.models,
    //         frames: framesLookup.frames,
    //         colours: coloursLookup.colours
    //     }
    // }, [modelsDataLookup, framesLookup, coloursLookup])
    
    const [isModifyModalOpen, setModifyModalOpen] = useState(false)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isDeleteLoading, setDeleteLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const dataBuilder = () => {
        const requiredFields = [
            {
                field: 'orderNo',
                label: 'Order No'
            },
            {
                field: 'customer',
                label: 'Customer'
            },
            {
                field: 'reference',
                label: 'Reference'
            },
        ]

        let fields = []

        for (const data of requiredFields) {
            if (!row[data.field]) continue;

            fields.push({
                label: data.label, 
                value: row[data.field]
            })
        }

        return fields
    }

    const handleOrderDelete = async () => {
        setDeleteLoading(true)

        const { data } = await API.delete(`${API_ENDPOINTS.ORDERS}/${row.id}` )

        if (data.success) {
            enqueueSnackbar('Order deleted successfully', {variant: 'success'})
            setDeleteLoading(false)
            queryClient.invalidateQueries(['sales'])
        }
    }


    // const _handleMouseClick = (event) => {
    //     // We don't want to close the dialog when clicking the dialog content.
    //     // Make sure the event starts and ends on the same DOM element.
    //     backdropClick.current = event.target === event.currentTarget
    // }

    // const _handleBackdropClick = (event) => {
    //     if (!backdropClick.current) {
    //         return
    //     }

    //     backdropClick.current = null

    //     _handleModalClose(event, 'backdropClick')
    // }

    // const _handleModalClose = (event, reason) => {
    //     setToggleModal(!isModalToggled)
    //     setSelectedProduct(null)
    // }

    // const _handleSendBack = async () => {
    //     setSendBackLoading(true)

    //     const { data } = await API.put(`${API_ENDPOINTS.POST_SEND_BACK}?page=${currPage}`, {id: selectedProduct.id})

    //     if (data?.success) {
    //         queryClient.invalidateQueries(['despatch'])
    //         queryClient.invalidateQueries(['orderBook'])
    //         enqueueSnackbar('Product sent back.', { variant: 'success'})
    //     } else {
    //         enqueueSnackbar('Failed to send back product', { variant: 'error'})
    //     }

    //     setSendBackLoading(false)
    // }

    // const _renderPrintedFormsAndLabelCount = () => {
    //     return (
    //         <Stack direction="row" alignItems={'center'} spacing={1} sx={{paddingRight: '16px'}}>
    //             <Chip 
    //                 variant="outlined"
    //                 icon={<Error />}
    //                 label={printCounters.unprintedCount}
    //                 color={'warning'}
    //             />

    //             <Chip 
    //                 label={printCounters.printedCount} 
    //                 variant="outlined"
    //                 icon={ <Check />}
    //                 color={'success'}
    //             />
    //         </Stack>
    //     )
    // }

    // const _renderUmbrellas = (umbrella, index) => {
    //     // const isPCFormsPrinted = (umbrella.orderChecklist & 1) === 0 
    //     // const isLabelsPrinted  = (umbrella.orderChecklist & 2) === 0

    //     const handleProductClick = () => {
    //         setToggleModal(!isModalToggled)
    //         umbrella = { 
    //             ...umbrella,
    //             orderNo: row.orderNo,
    //             customerName: row.customer,
    //             reference: row.reference,
    //             productIndex: index + 1
    //         }
    //         setSelectedProduct(umbrella)
    //     }

    //     return (
    //         <ListItem key={index}>
    //             <ListItemButton disableTouchRipple onClick={handleProductClick}>
    //                 <ListItemIcon>
    //                     <ChevronRight />
    //                 </ListItemIcon>
    //                 <ListItemText primary={umbrella.model.displayName} />

    //                 {/* <Stack direction="row" alignItems={'center'} justifyContent="flex-end" spacing={1}>
    //                     <Chip 
    //                         label={`PC Forms`} 
    //                         variant="outlined"
    //                         icon={ isPCFormsPrinted ? <Error /> : <Check />}
    //                         color={isPCFormsPrinted ? 'warning' : 'success'}
    //                     />

    //                     <Chip 
    //                         label={`Label`} 
    //                         variant="outlined"
    //                         icon={ isLabelsPrinted ? <Error /> : <Check />}
    //                         color={isLabelsPrinted ? 'warning' : 'success'}
    //                     />
    //                 </Stack> */}

    //             </ListItemButton>
    //         </ListItem>
    //     )
    // }

    // const _renderCustomAction = () => {
    //     if (currPage === 'sales' && isModalToggled) {
    //         return (
    //             <PermissionBarrier scopes={[ROLES.admin, ROLES.sales, ROLES.factory]}>
    //                 <ActionModal.Product 
    //                     type="modify"
    //                     initialProductDetails={getProductData(selectedProduct)}
    //                     queryKey={props.queryKey}
    //                     buttonProps={{
    //                         variant: 'contained'
    //                     }}
    //                 />
    //             </PermissionBarrier>
    //         )
    //     }

    //     if (isModalToggled) {
    //         return (
    //             <PermissionBarrier scopes={[ROLES.admin]}>
    //                 <ActionModal.Simple 
    //                     modalTitle="Send Back Product?"
    //                     dialogMsg={`Are you sure you would like to move this product back to ${currPage === 'despatch' ? 'Orderbook' : 'Sales'} ?`}
    //                     onConfirm={_handleSendBack}
    //                     btnText="Send Back"
    //                     isLoading={isSendBackLoading}
    //                     buttonProps={{
    //                         variant: 'text',
    //                         color: 'error'
    //                     }}
    //                 />
    //             </PermissionBarrier>
    //         )
    //     }

    //     return null
    // }

    return (
        <StyledPaper elevation={6} sx={{flex: 1, maxWidth: 720}}>
            <Stack height={'100%'} direction="column" justifyContent="flex-start" divider={<Divider flexItem />} spacing={1}>
                <Typography variant={'h6'} sx={{fontWeight: 'bold'}} align="center">Order Information</Typography>

                <Stack spacing={1} alignItems={'flex-start'} sx={{padding: '14px 0'}}>
                    {dataBuilder().map((data, index) => (
                        <Typography key={`${index}-${data.value}`} variant={'body1'}>
                            {data.label}: {data.value}
                        </Typography>
                    ))}
                </Stack>

                {/* <Accordion expanded={isProductExpanded} onChange={() => setProductExpanded(!isProductExpanded)}>
                    <AccordionSummary 
                        expandIcon={<ExpandMore />} 
                        sx={{
                            '& .MuiAccordionSummary-content': {
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }
                        }}
                        aria-controls="product-panel-content" 
                    >
                        <Typography>
                            View Umbrellas
                        </Typography>

                        {_renderPrintedFormsAndLabelCount()}
                    </AccordionSummary>

                    <AccordionDetails>
                        <List dense sx={{width: '100%'}}>
                            {_processedUmbrellas.map(_renderUmbrellas)}
                        </List>
                    </AccordionDetails>
                </Accordion> */}

                <Box>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        {/* <PermissionBarrier exception={!row.invNo} scopes={[ROLES.admin, ROLES.sales, ROLES.factory]}> */}
                            <Button onClick={() => setModifyModalOpen(true)} variant="contained">
                                {`Modify Order`}
                            </Button>

                            <ActionModal.Simple 
                                isOpen={isDeleteModalOpen}
                                modalTitle="Delete Order"
                                dialogMsg='Are you sure you want to delete this order?'
                                isLoading={isDeleteLoading}
                                onConfirm={handleOrderDelete}
                                btnText="Delete Order"
                                buttonProps={{
                                    color: 'error',
                                    
                                }}
                            />

                            {/* {(currPage === 'despatch') && 
                                    <ActionMenu 
                                        {...props.actionMenuProps}
                                        currentClient={{...row.customer}}
                                        row={row}
                                    />
                            } */}

                        {/* </PermissionBarrier> */}

                        {/* {(currPage === 'despatch' || currPage === 'history') && (
                                <ActionModal.ImageList currPage={currPage} orderNo={row.orderNo} />
                            )
                        } */}
                    </Stack>
                </Box>
            </Stack>

            <ActionModal.Order 
                isOpen={isModifyModalOpen} 
                onClose={() => setModifyModalOpen(false)} 
                type="modify" 
                initialOrderData={row} 
            />

            
            

            {/* <Modal 
                open={isModalToggled} 
                onClose={_handleModalClose}
                onClick={_handleBackdropClick}
                >
                <Container 
                    onMouseDown={_handleMouseClick} 
                    maxWidth="sm" 
                    sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        height: '100%', 
                        outline: 0
                    }}>
                    <ProductDetails 
                        product={selectedProduct}
                        currPage={currPage}
                        // customAction={_renderCustomAction()}
                        // hasInvoice={!!row.invNo}
                    />
                </Container>
            </Modal> */}
        </StyledPaper>
    )
}

export default OrderDetails
