import { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { 
    DialogContent, 
    DialogTitle,
    Button, 
    TextField, 
    DialogActions,
    IconButton,
    Grid,
    Autocomplete,
    createFilterOptions
} from "@mui/material"
import {  Add, Close, Edit } from "@mui/icons-material"
import { StyledDialog } from 'components/StyledDialog'
import { useForm, Controller } from "react-hook-form"
import { getProductName, getProductTypeData, ProductTypeOptions, PRODUCT_FIELDS, PRODUCT_TYPES } from "./products.data"
import { LoadingButton } from '@mui/lab'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { API } from 'api'
import { API_ENDPOINTS } from 'util/constants/api'
import { useColours, useFrames, useModelData, useProductData } from 'hooks/lookups.hooks'

const ProductModal = ({type, buttonProps, onProductSubmit, isIconButton = false, initialProductDetails, ...props}) => {
    // const { data: productsDataLookup } = useProductData()
    const { data: modelsDataLookup } = useModelData()    
    const { data: framesLookup } = useFrames()
    const { data: coloursLookup } = useColours()

    const _getInitialProductType = () => {
        if (initialProductDetails) {
            return ProductTypeOptions.find(productType => productType.code === initialProductDetails.model.company.toLowerCase())
        }

        return {
            code: '',
            displayName: ''
        }
    }

    const { 
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
        setValue
    } = useForm({
        defaultValues: null,
        mode: 'onChange'
    })

    const { enqueueSnackbar } = useSnackbar()
    const [isOpen, setOpen] = useState(false)
    const [selectedProductType, setProductType] = useState(_getInitialProductType())
    const [isSubmitLoading, setSubmitLoading] = useState(false)

    // For Refetching 
    const queryClient = useQueryClient()

    const modalType = {
        text: type  === 'add' ? 'Add' : 'Modify',
        icon: type  === 'add' ? <Add /> : <Edit />
    }

    const formRules = useMemo(() => ({required: 'This field is required'}), [])

    const _productDataList = useMemo(() => {
        const filteredModels = modelsDataLookup.models.filter((model) => model.company.toLowerCase() === selectedProductType.code) 

        return {
            models: filteredModels,
            frames: framesLookup.frames,
            colours: coloursLookup.colours,
            bases: modelsDataLookup.models.filter((model) => model.displayName.toLowerCase().includes('base'))
        }
    }, [modelsDataLookup, framesLookup, coloursLookup, selectedProductType.code])

    const filterOptions = createFilterOptions()
    const _renderFilterOptions = useCallback((options, params) => {
        const filtered = filterOptions(options, params)

        return filtered
    }, [filterOptions])

    const _getOptionLabels = (option) => {

        return option.displayName
    }

    const _handleProductTypeChange = (event, newValue) => {
        // Add default because autocomplete just gives you null if you clear value
        const value = newValue ? newValue : {code: '', displayName: ''}
        setProductType(value)

        reset(getProductTypeData())
    }

    const _getTypeOptions = useCallback((dataField, field) => {

        return _productDataList[dataField[field].options]

    }, [ _productDataList])

    const _renderProductFields = useCallback(() =>  {        
        const productFieldDetails = Object.keys(PRODUCT_TYPES[selectedProductType.code])
        const dataFields = PRODUCT_TYPES[selectedProductType.code]
        return productFieldDetails.map((field, index) => {
            const typeOptions = _getTypeOptions(dataFields, field)

            // Sort the options according to their groupBy's
            typeOptions.sort((a, b) => {
                if (a?.range && b?.range) {
                    return b.range.localeCompare(a.range)
                }

                // keep the original order otherwise
                return 0
            })

            return (
                <Grid item xs={6} key={index}>
                    <Controller 
                        name={dataFields[field]?.name}
                        control={control}
                        rules={dataFields[field]?.required && formRules}
                        render={({
                            field: {name, value, onChange, onBlur},
                        }) => (
                                <Autocomplete 
                                    name={name}
                                    value={value || null}
                                    onChange={(event, param) => onChange(param)}
                                    onBlur={onBlur}
                                    filterOptions={_renderFilterOptions}
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id={`product-select-${name}`}
                                    options={typeOptions}
                                    groupBy={(option) => option?.range}
                                    
                                    isOptionEqualToValue={(option, formValue) => option.code === formValue.code}
                                    getOptionLabel={_getOptionLabels}
                                    renderOption={(props, option) => <li {...props} key={option.code}>{option.displayName}</li>}
                                    renderInput={(params) => <TextField {...params} label={dataFields[name].label} error={!!errors[name]} helperText={errors[name]?.message}/>}
                                />
                            )
                        }
                    />
                </Grid>
            )
        })
    }, [control, errors, formRules, _renderFilterOptions, _getTypeOptions, selectedProductType])

    // Handlers
    const _handleOpen = (event) => {
        event.stopPropagation()
        setOpen(true)

        if (type === 'modify' && initialProductDetails) {
            Object.keys(PRODUCT_TYPES[selectedProductType.code]).forEach(field => {
                
                // Populate fields...
                setValue(field, initialProductDetails[field], {shouldValidate: true, shouldDirty: true})
            })
        }
    }

    const _handleClose = (event) => {
        event.stopPropagation()
        setOpen(false)
    }

    const _onSubmit = async (formData, event) => {
        const payload = {
            displayName: getProductName(null, formData.model.code, _productDataList.models)
        }
        
        for (const key of Object.keys(formData)) {
            payload[key] = formData[key]
        }

        // if has callback means not direct to API...
        if (onProductSubmit) {
            if (type === 'modify') {
                payload.indexId = initialProductDetails?.indexId            
                payload.id = initialProductDetails?.id
            }

            onProductSubmit(payload)
            setOpen(false)
            
            reset(getProductTypeData())

        } else {
            // API Call here since direct modify...
            // API calls made here are strictly for OrderBook only
            payload.id = initialProductDetails?.id
            
            const apiPayload = {
                formData: payload,
                pageKey: 'orderBook'
            }

            setSubmitLoading(true)
            const { data } = await API.put(API_ENDPOINTS.MODIFY_PRODUCT, apiPayload)

            setSubmitLoading(false)

            
            if (data?.success) {
                enqueueSnackbar('Product Updated', { variant: 'success'})
                setOpen(false)
                queryClient.invalidateQueries(props.queryKey)
                reset(getProductTypeData())
                _handleProductTypeChange()

            } else {
                enqueueSnackbar(data.message, { variant: 'error'})
            }
        }

        // Only reset for adding
        // since each product has their own modal instance...
        // if (type !== 'modify') {
        //     setProductType(null)
        // }
    }

    // Effects
    useEffect(() => {
        reset(getProductTypeData())
    }, [reset])

    return ( 
        <>
            {isIconButton ? 
                <IconButton onClick={_handleOpen} color="primary" {...buttonProps}>
                    {modalType.icon}
                </IconButton>
                :
                <Button onClick={_handleOpen} {...buttonProps}>
                    {`${modalType.text} Product`}
                </Button>
            }
            <StyledDialog 
                open={isOpen}
                // ...prevent accordion from opening and closing when clicking things in the modal.
                onClick={(e) => e.stopPropagation()} 
                onClose={_handleClose} 
                maxWidth="sm" 
                fullWidth
            >
                <DialogTitle sx={{m: 0, pt: 2, alignItems: 'center', display: 'flex'}}  >
                    {`${modalType.text} Product`}
                    <IconButton
                        aria-label="close"
                        onClick={_handleClose}
                        sx={{
                            marginLeft: 'auto',
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete 
                                value={selectedProductType}
                                onChange={_handleProductTypeChange}
                                filterOptions={_renderFilterOptions}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-product-select"
                                options={ProductTypeOptions}
                                getOptionLabel={_getOptionLabels}
                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                renderOption={(props, option) => <li {...props} key={option.displayName}>{option.displayName}</li>}
                                renderInput={(params) => <TextField {...params} label="Product Type"/>}
                            />
                        </Grid>

                        {selectedProductType.code && _renderProductFields()}

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button variant="text" sx={{marginRight: 'auto'}} onClick={_handleClose}>
                        Cancel
                    </Button>

                    <LoadingButton 
                        variant="contained" 
                        loading={isSubmitLoading}
                        disabled={!isValid || !selectedProductType.code} 
                        onClick={handleSubmit(_onSubmit)}>
                        {modalType.text} Product
                    </LoadingButton>
                </DialogActions>
            </StyledDialog>
        </>
    )
}

export default memo(ProductModal)